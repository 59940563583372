import React, { useEffect, useState } from "react";
import MainForm from '../components/MainForm/MainForm'
import { connect } from "react-redux";
import { setCreateReservation, } from '../store/actions/reservationAction'
import { setAllProperty, setAllAvailRoomByDate, } from '../store/actions/propertyAction'
import { setAllHotel, } from '../store/actions/hotelAction'
import { checkResponseMessage } from '../helper/helper'

const AddReservationPage = ({ dispatch, dataReservation, dataProperty, dataHotel }) => {
  const [isLoading, setIsLoading] = useState(true);

  const [name, setName] = useState("");
  const [KTP, setKTP] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [bookingSource, setBookingSource] = useState("");
  const [price, setPrice] = useState("");
  const [bookTime, setBookTime] = useState("");
  const [guests, setGuests] = useState("");
  const [roomType, setRoomType] = useState("");
  const [notes, setNotes] = useState("");
  const [country, setCountry] = useState("");
  const [paymentMethod, setPaymentMethod] = useState({id: 0, name: "Cash"});
  
  const [allAvailHotel, setAllAvailHotel] = useState([]);
  const [allAvailBookingSource, setAllAvailBookingSource] = useState([
    {id: 0, name:"Direct Booking"},
    {id: 1, name:"Walk In Guest"},
    {id: 2, name:"OTA Traveloka"},
    {id: 3, name:"OTA Tiket.com"},
    {id: 4, name:"OTA Agoda"},
    {id: 5, name:"OTA Booking.com"},
    {id: 6, name:"OTA C-TRIP"},
    {id: 7, name:"OTA Expedia"},
    {id: 8, name:"OTA KlikandBook"},
    {id: 9, name:"OTA MG Holiday"},
    {id: 10, name:"OTA ZUZU"},
    {id: 11, name:"OTA RedDoorz"},
  ]);
  const [allAvailPaymentMethod, setAllAvailPaymentMethod] = useState([
    {id: 1, name:"Cash"},
    {id: 2, name:"Debit Card"},
    {id: 3, name:"Credit Card"},
    {id: 4, name:"Qris"},
    {id: 5, name:"City Ledger to NamRoom.id"},
    {id: 6, name:"City Ledger to Zuzu"},
    {id: 7, name:"City Ledger to Reddorz"},
    {id: 8, name:"Staff Ledger"},
  ]);

  const [roomNumber, setRoomNumber] = useState([]);
  const [selectedHotel, setSelectedHotel] = useState([]);
  const [allRoom, setAllRoom] = useState("");
  const [allAvailRoom, setAllAvailRoom] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState({id: "", name:""});
  
  const [birthDate, setBirthDate] = useState(null);
  const [checkin, setCheckin] = useState(Date.now());
  const [checkout, setCheckout] = useState(Date.now() + + 24 * 60 * 60 * 1000);

  const [province, setProvince] = useState("");
  const [city, setCity] = useState("");
  const [selectedProvince, setSelectedProvince] = useState("");
  const [selectedCity, setSelectedCity] = useState("");

  const doCreateReservation = (e) => {
    e.preventDefault()
    const dataCreate = {
      name,
      id_card: KTP,
      address,
      email,
      country,
      status:1,
      hotel_id: selectedHotel ? selectedHotel.id : localStorage.getItem('hotelId'),
      phone,
      price,
      room_number: roomNumber,
      property_id: selectedRoom.id,
      notes,
      birth_date: Math.floor(birthDate),
      guests,
      payment_method: paymentMethod.name,
      booking_source: bookingSource.name,
      start_date: Math.floor(checkin),
      end_date: Math.floor(checkout),
    }
    setCreateReservation(dispatch, dataCreate)
  }

  const handleRoomType = (allRoom, type) => {
    let result = []
    for( let i=0 ; i<allRoom.length ; i++ ){
      let oneRoom = {}
      oneRoom['id'] = allRoom[i].id
      oneRoom['name'] = allRoom[i].type
      if( type === "availability" ){
        oneRoom['status'] = allRoom[i].status
        oneRoom['available_rooms'] = allRoom[i].available_rooms
      }
      if(oneRoom.id == roomType){
        oneRoom['selected'] = true;
      }else{
        oneRoom['selected'] = false;
      }
      result.push(oneRoom)
    }
    
    if( type === "availability" ){
      setAllAvailRoom(result)
    }else{
      setAllRoom(result)
    }
    setIsLoading(false)
  }
  
  const handleSelect = (e, type) => {
    const splitValue = e.target.value.split("||")
    if(type === "Nama Hotel"){
      setSelectedHotel({id: splitValue[0], type: splitValue[1]}) 
    }else if(type === "Metode Pembayaran") {
      setPaymentMethod({id: splitValue[0], name: splitValue[1]}) 
    }else{
      setBookingSource({id: splitValue[0], name: splitValue[1]}) 
    }
  }

  const checkAvailRoom = (e) => {
    e.preventDefault()
    let param={
      start_date: Math.floor(checkin),
      end_date: Math.floor(checkout),
    }
    if( localStorage.getItem('is_super_admin') === 'true' ){
      param['hotel_id'] = selectedHotel.id
    }else{
      param['hotel_id'] = localStorage.getItem('hotelId')
    }
    setAllAvailRoomByDate(dispatch, param)
    setBookTime(new Date(checkin).toLocaleDateString('en-GB') + " -> " + new Date(checkout).toLocaleDateString('en-GB'))
  }

  const selectAvailRoom = (e, roomId, roomName) => {
    e.preventDefault()
    setSelectedRoom({id: roomId, name:roomName})
    checkResponseMessage(true, "Success", "Sukses memilih kamar")
  }

  const doSelectRoom = (e, selectedRoomIdx) => {
    let result = [...allRoom]
    for (let i = 0; i < result.length; i++) {
      if( i == selectedRoomIdx ){
        result[i]['selected'] = !result[i]['selected'];
      }else{
        result[i]['selected'] = false;
      }
    }
    setAllRoom(result)
  }

  const adjustDataFormat = (data, section) => {
    if( section === "province "){
      setProvince(data)
    }else{
      setCity(data)
    }
  }

  useEffect(()=>{
    if( dataReservation.createReservationResp ){
      let data = dataReservation.createReservationResp
      setName(data.name)
      setEmail(data.email)
      setIsLoading(false)
    }
  },[dataReservation.createReservationResp])

  useEffect(()=>{
    if( dataProperty.propertyResp ){
      handleRoomType(dataProperty.propertyResp)
    }
  },[dataProperty.propertyResp])

  useEffect(()=>{
    if( dataProperty.allAvailRoomResp ){
      handleRoomType(dataProperty.allAvailRoomResp.data, "availability")
    }
  },[dataProperty.allAvailRoomResp])

  useEffect(()=>{
    if( dataReservation.allProvinceResp ){
      let data = dataReservation.allProvinceResp
      adjustDataFormat(data, "province")
    }
  },[dataReservation.allProvinceResp])

  useEffect(()=>{
    if( dataReservation.allCityResp ){
      let data = dataReservation.allCityResp
      adjustDataFormat(data, "city")
    }
  },[dataReservation.allCityResp])

  useEffect(()=>{
    if( dataHotel.hotelResp?.length > 0 ){
      setAllAvailHotel(dataHotel.hotelResp)
      setSelectedHotel(dataHotel.hotelResp[0])
      setAllProperty(dispatch,"", dataHotel.hotelResp[0].id)
    }else{
      setIsLoading(false)
    }
  },[dataHotel.hotelResp])

  useEffect(()=>{
    if( localStorage.getItem('is_super_admin') === 'true' && selectedHotel) {
      setAllProperty(dispatch,"", selectedHotel.id)
    }
  },[selectedHotel])

  useEffect(()=>{
    if( localStorage.getItem('is_super_admin') === 'true' ) {
      setAllHotel(dispatch)
    }else{
      setAllProperty(dispatch,"", localStorage.getItem('hotelId'))
    }
  },[dispatch])


  const dataFormSuperAdmin = [
    {
      label: "Nama Hotel",
      type: "dropdown",
      section: "Nama Hotel",
      placeholder: "Masukkan Nama Hotel",
      spaceMd: "6",
      spaceXs: "12",
      action: handleSelect,
      required: true,
      value: selectedHotel,
      dataDropdown: allAvailHotel,
    },{
      type: "reservation",
      label:"Pilih Tanggal Booking",
      spaceMd: "12",
      spaceXs: "12",
      required: true,
      rooms: allRoom,
      onClickRoom: doSelectRoom,
      onClickCheckAvail: checkAvailRoom, 
      valueCheckin: checkin,
      actionCheckin: setCheckin,
      valueCheckout: checkout,
      actionCheckout: setCheckout,
    },{
      label: "All Available room",
      type: "tableRoom",
      spaceMd: "12",
      spaceXs: "12",
      required: true,
      value: allAvailRoom,
      action: selectAvailRoom,
    },{
      label: "Book Time",
      type: "text",
      placeholder: "Masukkan Book Time",
      spaceMd: "12",
      spaceXs: "12",
      required: true,
      notEditable: true,
      value: bookTime,
      action: setBookTime,
    },{
      label: "Tipe Kamar*",
      type: "text",
      placeholder: "Masukkan Tipe Kamar",
      spaceMd: "6",
      spaceXs: "12",
      notEditable: true,
      required: true,
      value: selectedRoom.name,
    },{
      label: "Nomor Kamar*",
      type: "text",
      placeholder: "Masukkan Nomor Kamar",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: roomNumber,
      action: setRoomNumber,
    },{
      type: "SEPARATOR",
      spaceMd: "12",
      spaceXs: "12",
    },{
      label: "Nama*",
      type: "text",
      placeholder: "Masukkan Nama",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: name,
      action: setName,
    },{
      label: "No KTP",
      type: "text",
      placeholder: "Masukkan No KTP",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: KTP,
      action: setKTP,
    },{
      label: "Email",
      type: "text",
      placeholder: "Masukkan Email",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: email,
      action: setEmail,
    },{
      label: "Nomor HP",
      type: "text",
      placeholder: "Masukkan Nomor HP",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: phone,
      action: setPhone,
    },{
      label: "Tanggal Lahir",
      type: "dateAdd",
      placeholder: "Masukkan Tanggal Lahir",
      spaceMd: "6",
      spaceXs: "12",
      availability:true,
      value: birthDate,
      action: setBirthDate,
      required: true,
    },{
      label: "Negara",
      type: "text",
      placeholder: "Masukkan Negara",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: country,
      action: setCountry,
    },{
      label: "Alamat",
      type: "text",
      placeholder: "Masukkan Alamat",
      spaceMd: "12",
      spaceXs: "12",
      required: true,
      value: address,
      action: setAddress,
    },{
      type: "SEPARATOR",
      spaceMd: "12",
      spaceXs: "12",
    },{
      label: "Booking Source*",
      section: "Booking Source",
      type: "dropdown",
      placeholder: "Masukkan Booking Source",
      spaceMd: "6",
      spaceXs: "12",
      action: handleSelect,
      required: true,
      value: bookingSource,
      dataDropdown: allAvailBookingSource,
    },{
      label: "Total Pengunjung*",
      type: "text",
      placeholder: "Masukkan Total Pengunjung",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: guests,
      action: setGuests,
    },{
      label: "Metode Pembayaran*",
      section: "Metode Pembayaran",
      type: "dropdown",
      placeholder: "Masukkan Metode Pembayaran",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      action: handleSelect,
      required: true,
      value: paymentMethod,
      dataDropdown: allAvailPaymentMethod,
    },{
      label: "Catatan",
      type: "text",
      placeholder: "Masukkan Catatan",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: notes,
      action: setNotes,
    },{
      type: "SEPARATOR",
      spaceMd: "12",
      spaceXs: "12",
    },{
      label: "Total Harga*",
      type: "text",
      placeholder: "Masukkan Total Harga",
      spaceMd: "12",
      spaceXs: "12",
      required: true,
      value: price,
      action: setPrice,
    },{
      label: "Simpan",
      type: "button_submit",
      spaceMd: "3",
      spaceXs: "3",
      action: doCreateReservation,
    },{
      label: "Kembali",
      type: "buttonWhite",
      spaceMd: "3",
      link: '../reservation',
      spaceXs: "3",
    }
  ]

  const dataForm = [
    {
      type: "reservation",
      label:"Pilih Tanggal Booking",
      spaceMd: "12",
      spaceXs: "12",
      required: true,
      rooms: allRoom,
      onClickRoom: doSelectRoom,
      onClickCheckAvail: checkAvailRoom, 
      valueCheckin: checkin,
      actionCheckin: setCheckin,
      valueCheckout: checkout,
      actionCheckout: setCheckout,
    },{
      label: "All Available room",
      type: "tableRoom",
      spaceMd: "12",
      spaceXs: "12",
      required: true,
      value: allAvailRoom,
      action: selectAvailRoom,
    },{
      label: "Book Time",
      type: "text",
      placeholder: "Masukkan Book Time",
      spaceMd: "12",
      spaceXs: "12",
      required: true,
      notEditable: true,
      value: bookTime,
      action: setBookTime,
    },{
      label: "Tipe Kamar*",
      type: "text",
      placeholder: "Masukkan Tipe Kamar",
      spaceMd: "6",
      spaceXs: "12",
      notEditable: true,
      required: true,
      value: selectedRoom.name,
    },{
      label: "Nomor Kamar*",
      type: "text",
      placeholder: "Masukkan Nomor Kamar",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: roomNumber,
      action: setRoomNumber,
    },{
      type: "SEPARATOR",
      spaceMd: "12",
      spaceXs: "12",
    },{
      label: "Nama*",
      type: "text",
      placeholder: "Masukkan Nama",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: name,
      action: setName,
    },{
      label: "No KTP",
      type: "text",
      placeholder: "Masukkan No KTP",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: KTP,
      action: setKTP,
    },{
      label: "Email",
      type: "text",
      placeholder: "Masukkan Email",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: email,
      action: setEmail,
    },{
      label: "Nomor HP",
      type: "text",
      placeholder: "Masukkan Nomor HP",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: phone,
      action: setPhone,
    },{
      label: "Tanggal Lahir",
      type: "dateAdd",
      placeholder: "Masukkan Tanggal Lahir",
      spaceMd: "6",
      spaceXs: "12",
      availability:true,
      value: birthDate,
      action: setBirthDate,
      required: true,
    },{
      label: "Negara",
      type: "text",
      placeholder: "Masukkan Negara",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: country,
      action: setCountry,
    },{
      label: "Alamat",
      type: "text",
      placeholder: "Masukkan Alamat",
      spaceMd: "12",
      spaceXs: "12",
      required: true,
      value: address,
      action: setAddress,
    },{
      type: "SEPARATOR",
      spaceMd: "12",
      spaceXs: "12",
    },{
      label: "Booking Source*",
      section: "Booking Source",
      type: "dropdown",
      placeholder: "Masukkan Booking Source",
      spaceMd: "6",
      spaceXs: "12",
      action: handleSelect,
      required: true,
      value: bookingSource,
      dataDropdown: allAvailBookingSource,
    },{
      label: "Total Pengunjung*",
      type: "text",
      placeholder: "Masukkan Total Pengunjung",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: guests,
      action: setGuests,
    },{
      label: "Metode Pembayaran*",
      section: "Metode Pembayaran",
      type: "dropdown",
      placeholder: "Masukkan Metode Pembayaran",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      action: handleSelect,
      required: true,
      value: paymentMethod,
      dataDropdown: allAvailPaymentMethod,
    },{
      label: "Catatan",
      type: "text",
      placeholder: "Masukkan Catatan",
      spaceMd: "6",
      spaceXs: "12",
      required: true,
      value: notes,
      action: setNotes,
    },{
      type: "SEPARATOR",
      spaceMd: "12",
      spaceXs: "12",
    },{
      label: "Total Harga*",
      type: "text",
      placeholder: "Masukkan Total Harga",
      spaceMd: "12",
      spaceXs: "12",
      required: true,
      value: price,
      action: setPrice,
    },{
      label: "Simpan",
      type: "button_submit",
      spaceMd: "3",
      spaceXs: "3",
      action: doCreateReservation,
    },{
      label: "Kembali",
      type: "buttonWhite",
      spaceMd: "3",
      link: '../reservation',
      spaceXs: "3",
    }
  ]

  return (    
    isLoading === false && 
    <div className="container_right_form">
      <MainForm
        pageName={"Tambah Reservation"}
        dataForm={localStorage.getItem('is_super_admin') === 'true' ? dataFormSuperAdmin : dataForm}
        linkAccReview={"../accountReview"}
        pageFor={"detail"}
        email={email}
        onSubmit={doCreateReservation}
        whiteBackground={true}
      />
    </div>
  );
};

const storage = state => {
  return {
    dataReservation: state.reservation,
    dataProperty: state.property,
    dataHotel: state.hotel,
  };
};

export default connect(
  storage
)(AddReservationPage)
