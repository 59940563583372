import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom'
import { Row } from 'react-bootstrap'
import { Page, Text, View, Document, StyleSheet, Image, PDFViewer } from '@react-pdf/renderer';

// import { Document, Page, Text, StyleSheet, PDFViewer, Image, } from '@react-pdf/renderer';

const InvoicePage = () => {
  // const [datas, setDatas] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  
  const styles = StyleSheet.create({
    page: {
      padding: 30,
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 20,
    },
    logo: {
      width: 50,
      height: 50,
    },
    titleContainer: {
      textAlign: 'center',
      flexGrow: 1,
    },
    title: {
      fontSize: 16,
      fontWeight: 'bold',
    },
    subHeader: {
      fontSize: 10,
      textAlign: 'center',
      marginBottom: 20,
    },
    table: {
      display: 'table',
      width: 'auto',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#bfbfbf',
      marginBottom: 10,
    },
    tableRow: {
      flexDirection: 'row',
    },
    tableColHeader: {
      width: '20%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#bfbfbf',
      backgroundColor: '#f0f0f0',
      padding: 5,
    },
    tableCol: {
      width: '50%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#bfbfbf',
      padding: 5,
    },
    tableColFull: {
      width: '100%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#bfbfbf',
      padding: 5,
    },
    tableCellHeader: {
      fontSize: 10,
      fontWeight: 'bold',
    },
    tableCell: {
      fontSize: 10,
    },
    checkboxField: {
      marginLeft: 5,
      fontSize: 10,
    },
    signatureRow: {
      marginTop: 30,
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    signatureField: {
      width: '45%',
      textAlign: 'center',
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      paddingBottom: 10,
    },
    footer: {
      marginTop: 30,
      textAlign: 'center',
      fontSize: 10,
    },
  });
  
  // Sample data for the table
  const reservationDetails = {
    checkIn: '2024-11-01',
    checkOut: '2024-11-05',
    dailyRate: 'Rp 1,000,000',
    roomNumber: '101',
    numberOfGuests: 2,
    name: 'John Doe',
    address: 'Jl. Sudirman No. 123',
    city: 'Jakarta',
    mobilePhone: '08123456789',
    postalCode: '12345',
    email: 'john.doe@example.com',
    country: 'Indonesia',
    idCard: 'KTP 1234567890',
    birthDate: '1985-05-15',
    deposit: 'Rp 500,000',
    paymentMethod: 'Tunai',
    receipt: 'Tanda Terima #123',
  };

  useEffect(()=>{
    // setIsLoading(true)
    // setDatas(JSON.parse( localStorage.getItem("dataInvoice") ) );
    // setIsLoading(false)
  },[])

  const MyDocument = ({datas}) => {
    return(
    <>
      <Document>
        <Page style={styles.page}>
          {/* Header */}
          <View style={styles.header}>
            <Image style={styles.logo} src="path_to_your_logo" />
            <View style={styles.titleContainer}>
              <Text style={styles.title}>Pendaftaran</Text>
            </View>
          </View>

          {/* Sub-header */}
          <Text style={styles.subHeader}>
            MOHON DIPERHATIKAN BAHWA BATAS WAKTU CHECK OUT ADALAH JAM 12:00 SIANG, WAKTU CHECK IN ADALAH JAM 14:00 SIANG TERGANTUNG KETERSEDIAAN KAMAR
            {'\n'}PLEASE NOTE THAT THE CHECK-OUT TIME IS 12:00 PM, AND CHECK-IN TIME IS 2:00 PM SUBJECT TO ROOM AVAILABILITY
          </Text>

          {/* Table Header */}
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Check In</Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Check Out</Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Daily Room Rate</Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Room Number</Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>No. of Guests</Text>
              </View>
            </View>

            {/* Table Values */}
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{reservationDetails.checkIn}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{reservationDetails.checkOut}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{reservationDetails.dailyRate}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{reservationDetails.roomNumber}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{reservationDetails.numberOfGuests}</Text>
              </View>
            </View>

            {/* Name */}
            <View style={styles.tableRow}>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Name</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{reservationDetails.name}</Text>
              </View>
            </View>

            {/* Address */}
            <View style={styles.tableRow}>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Alamat</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{reservationDetails.address}</Text>
              </View>
            </View>

            {/* City & Mobile Phone */}
            <View style={styles.tableRow}>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Kota</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{reservationDetails.city}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Mobile Phone</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{reservationDetails.mobilePhone}</Text>
              </View>
            </View>

            {/* Postal Code & Email */}
            <View style={styles.tableRow}>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Kode Pos</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{reservationDetails.postalCode}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Email</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{reservationDetails.email}</Text>
              </View>
            </View>

            {/* Country & ID Card */}
            <View style={styles.tableRow}>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Negara</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{reservationDetails.country}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Passport / KTP</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{reservationDetails.idCard}</Text>
              </View>
            </View>

            {/* Birth Date & Deposit */}
            <View style={styles.tableRow}>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Tanggal Lahir</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{reservationDetails.birthDate}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Uang Muka / Deposit</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{reservationDetails.deposit}</Text>
              </View>
            </View>

            {/* Payment Method */}
            <View style={styles.tableRow}>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Cara Pembayaran</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.checkboxField}>☐ Tunai</Text>
                <Text style={styles.checkboxField}>☐ Kartu kredit</Text>
                <Text style={styles.checkboxField}>☐ Charge to company</Text>
              </View>
            </View>

            {/* Receipt */}
            <View style={styles.tableRow}>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Tanda Terima</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{reservationDetails.receipt}</Text>
              </View>
            </View>
          </View>

          {/* Additional Text Before Signature */}
          <Text style={{ marginTop: 20, textAlign: 'center', fontSize: 10 }}>
            Hotel tidak bertanggung jawab atas barang berharga yang ditinggal di dalam kamar. Safety deposit box tersedia di kamar
            {'\n'}The hotel is not responsible for valuables left in the room. Safety deposit boxes are available in the room
          </Text>

          {/* Signature Section */}
          <View style={styles.signatureRow}>
            <View style={styles.signatureField}>
              <Text>Tanda Tangan Tamu</Text>
              <Text>Guest Signature</Text>
            </View>
            <View style={styles.signatureField}>
              <Text>Karyawan</Text>
              <Text>Clerk Initial</Text>
            </View>
          </View>

          {/* Footer */}
          <View style={styles.footer}>
            <Text style={styles.footerHeader}>Namroom</Text>
            <Text>Jl, Pinangsia Raya No 1. Jakarta</Text>
            <Text>Phone : +62 89666424263</Text>
            <Text>email : namglodokhotel@gmail.com | Namroom.id</Text>
          </View>
        </Page>
      </Document>
    </>
    )
  };

  const download = (e) => {
    e.preventDefault()
    ReactDOM.render(<MyDocument />, `Desktop/example.pdf`);
  }

  return (    
    <>
      { !isLoading && 
        <Row className="invoiceContainer text-center">
          <PDFViewer className="mt-5">
            {/* <MyDocument datas={datas}/> */}
            <MyDocument/>
          </PDFViewer>
        </Row>
      }
    </>
  );
};

export default InvoicePage;
