const initialState = {
  allReservationResp: null,
  reservationDetailResp: null,
  reservationDetailUpdateResp: null,
  createReservationResp: null,
  allProvinceResp: null,
  allCityResp: null,
  reservationPassResp: null,
}
  
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ALL_RESERVATION': {
      return {...state, allReservationResp: action.payload}
    }
    case 'SET_CREATE_RESERVATION': {
      return {...state, createReservationResp: action.payload.data}
    }
    case 'SET_RESERVATION_DETAIL_RESP':{
      return {...state, reservationDetailResp: action.payload}
    }
    case 'SET_UPDATE_PASS_RESERVATION_RESP':{
      return {...state, reservationPassResp: action.payload.data}
    }
    case 'SET_UPDATE_RESERVATION_RESP':{
      return {...state, reservationDetailUpdateResp: action.payload}
    }
    case 'RESET_CREATE_RESERVATION':{
      return {...state, createReservationResp: null}
    }
    case 'SET_ALL_PROVINCE': {
      return {...state, allProvinceResp: action.payload}
    }
    case 'SET_ALL_CITY': {
      return {...state, allCityResp: action.payload}
    }
    default:
      return state
  }
}

export default reducer
